import { Container, Nav } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import colorSharp from "../assets/img/color-sharp.png";
import React, { useState } from "react";

export const Skills = () => {
  const [activeCategory, setActiveCategory] = useState("Frontend");
  const responsiveNav = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context("../assets/img/skills/", false, /\.(png|jpe?g|svg)$/)
  );

  const responsiveSkills = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const skillsData = {
    Frontend: [
      { image: images["vuejs.png"], text: "Vue.js" },
      { image: images["angular.png"], text: "Angular.js" },
      { image: images["react.png"], text: "React.js" },
      { image: images["html5.png"], text: "HTML" },
      { image: images["css3.png"], text: "CSS" },
    ],
    Backend: [
      { image: images["node.png"], text: "Node" },
      { image: images["spring.png"], text: "Spring" },
      { image: images["express.png"], text: "Express" },
    ],
    Programming_languages: [
      { image: images["java.png"], text: "Java" },
      { image: images["c.png"], text: "C" },
      { image: images["python.png"], text: "Python" },
      { image: images["elixir.png"], text: "Elixir" },
      { image: images["php.png"], text: "PHP" },
      { image: images["javascript.png"], text: "Javascript" },
    ],
    Mobile: [
      { image: images["android.png"], text: "Android" },
      { image: images["react.png"], text: "Reactnative" },
    ],
    Database: [
      { image: images["mongodb.png"], text: "MongoDB" },
      { image: images["mysql.png"], text: "MySQL" },
      { image: images["postgresql.png"], text: "PostgreSQL" },
    ],
    Devops: [
      { image: images["aws.png"], text: "AWS" },
      { image: images["docker.png"], text: "Docker" },
      { image: images["vagrant.png"], text: "Vagrant" },
    ],
    Software: [
      { image: images["figma.png"], text: "Figma" },
      { image: images["drawio.png"], text: "Draw.io" },
      { image: images["postman.png"], text: "Postman" },
    ],
    Others: [
      { image: images["github.png"], text: "Github" },
      { image: images["gitlab.png"], text: "Gitlab" },
      { image: images["windows.png"], text: "Windows" },
      { image: images["linux.png"], text: "Linux" },
    ],
  };

  return (
    <section className="project" id="skills">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="skill-bx wow zoomIn">
              <Container>
                <h2>Skills</h2>
                <br />
                <br />
                <Carousel
                  responsive={responsiveNav}
                  infinite={true}
                  variant="pills"
                  className="nav-pills mb-5 align-items-center nav-carousel skill-border"
                  style={{ border: "1px solid rgba(255, 255, 255, 0.5)" }}
                  id="pills-tab"
                  ssr={true}
                  arrows={true}
                  defaultActiveKey="Frontend"
                >
                  {Object.keys(skillsData).map((category) => (
                    <Nav.Item key={category}>
                      <Nav.Link
                        eventKey={category}
                        onClick={() => setActiveCategory(category)}
                        style={{
                          border: "1px solid rgba(255, 255, 255, 0.5)",
                        }}
                        className={
                          activeCategory === category ? "active-nav-link" : ""
                        }
                      >
                        {category}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Carousel>

                <Carousel
                  responsive={responsiveSkills}
                  infinite={true}
                  className="owl-carousel owl-theme skill-slider"
                >
                  {skillsData[activeCategory].map((skill, index) => (
                    <div className="item" key={index}>
                      <div className="image-container">
                        <img
                          src={skill.image}
                          alt="Image"
                          className="image-content"
                        />
                      </div>
                      <h5>{skill.text}</h5>
                    </div>
                  ))}
                </Carousel>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <img className="background-image-left" src={colorSharp} alt="Image" />
    </section>
  );
};
