import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import timemanager from "../assets/img/project/timemanager.jpg";
import muse from "../assets/img/project/muse.jpg";
import yzli from "../assets/img/project/yzli.jpg";
import cryptobreak from "../assets/img/project/crypto.jpg";
import daybreak from "../assets/img/project/news.jpg";
import kyudo from "../assets/img/project/kyudo.jpg";
import rdv from "../assets/img/project/rdv-prefecture.jpg";
import quest from "../assets/img/project/quest.jpg";
import trombi from "../assets/img/project/trombi.png";
import colorSharp2 from "../assets/img/color-sharp2.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const projects = [
    {
      title: "Time Manager Application",
      description: "Design & Development",
      imgUrl: timemanager,
      technologies: "Elixir, Vue, PostgreSQL, Docker",
    },
    {
      title: "Music Search Application",
      description: "Design & Development",
      imgUrl: muse,
      technologies: "PHP, Filezilla, Alwaysdata, PostgreSQL",
    },
    {
      title: "Canteen Payement simulation",
      description: "Design & Development",
      imgUrl: yzli,
      technologies: "PostgreSQL, PHP, Java, HTML, CSS",
    },
    {
      title: "Crypto tracking evolution application",
      description: "Design & Development",
      imgUrl: cryptobreak,
      technologies: "Express, MongoDB, Angular, Docker",
    },
    {
      title: "News Catalog application",
      description: "Design & Development",
      imgUrl: daybreak,
      technologies: "Javascript, PHP, JQuery, HTML, CSS, Ajax, PostgreSQL",
    },
    {
      title: "Tournament simulator project",
      description: "Design & Development",
      imgUrl: kyudo,
      technologies: "C",
    },
    {
      title: "Date tracker",
      description: "Design & Development",
      imgUrl: rdv,
      technologies: "Python",
    },
    {
      title: "Adventure game",
      description: "Design & Development",
      imgUrl: quest,
      technologies: "Java",
    },
    {
      title: "Trombinoscope",
      description: "Design & Development",
      imgUrl: trombi,
      technologies: "Javascript, PHP, JQuery, HTML, CSS, Ajax, PostgreSQL",
    },
  ];

  const web_mobile_projects = [
    {
      title: "Time Manger Application",
      description: "Design & Development",
      imgUrl: timemanager,
      technologies: "",
    },
    {
      title: "Music Search Application",
      description: "Design & Development",
      imgUrl: muse,
      technologies: "",
    },
    {
      title: "Canteen Payement simulation",
      description: "Design & Development",
      imgUrl: yzli,
      technologies: "",
    },
    {
      title: "Crypto tracking evolution application",
      description: "Design & Development",
      imgUrl: cryptobreak,
      technologies: "",
    },
    {
      title: "News Catalog application",
      description: "Design & Development",
      imgUrl: daybreak,
      technologies: "",
    },
    {
      title: "Trombinoscope",
      description: "Design & Development",
      imgUrl: trombi,
      technologies: "Javascript, PHP, JQuery, HTML, CSS, Ajax, PostgreSQL",
    },
  ];

  const application_projects = [
    {
      title: "Tournament simulator project",
      description: "Design & Development",
      imgUrl: kyudo,
      technologies: "",
    },
    {
      title: "Date tracker",
      description: "Design & Development",
      imgUrl: rdv,
      technologies: "",
    },
    {
      title: "Adventure game",
      description: "Design & Development",
      imgUrl: quest,
      technologies: "",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <br />
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">All Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Web & Mobile Projects
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">
                          Application Projects
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {projects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {web_mobile_projects.map(
                            (web_mobile_project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...web_mobile_project}
                                />
                              );
                            }
                          )}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {application_projects.map(
                            (application_project, index) => {
                              return (
                                <ProjectCard
                                  key={index}
                                  {...application_project}
                                />
                              );
                            }
                          )}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="bg"></img>
    </section>
  );
};
