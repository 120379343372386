import { Col } from "react-bootstrap";

export const ProjectCard = ({ title, description, imgUrl, technologies }) => {
  const backgroundStyle = {
    backgroundImage: `url(${imgUrl})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };

  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx" style={backgroundStyle}>
        <div className="proj-txtx">
          <h4>{title}</h4>
          <span>{description}</span>
          <p>{technologies}</p>
        </div>
      </div>
    </Col>
  );
};
